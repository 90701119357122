<!--
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
	<div id="app">
    <router-view />
	</div>
</template>

<script>

	export default ({
		computed: {
			// Sets components name based on current route's specified layout, defaults to
			// <layout-default></layout-default> component.
			layout() {
				return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
			}
		},
    mounted() {
      this.changePageTitle('');
    },
    methods: {
      changePageTitle(title) {
        document.title = title;
      }
    }
	})

</script>

<style lang="less">
@import "~@/assets/theme.less";
</style>
<!--<style lang="scss">-->
<!--@import "~@/scss/app.scss";-->
<!--</style>-->
