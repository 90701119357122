// src/configs/axios.js
import axios from 'axios';
import store from '@/store/store';
import router from '@/router';

const instance = axios.create({
    baseURL: `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api/v1`, // Replace with your backend server URL
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.access) {
            config.headers['Authorization'] = 'Bearer ' + user.access;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(response => response,
    ({ message, response: { status, config: { url }, data } }) => {
        console.log('status', status)
        if (status === 401){
            store.dispatch('user/logout');
            router.push({ name: 'login' });
        }
        // Если превышено время ожидания
        //     if (message.match('timeout')) Vue.$vs.notify({


        // console.log('Vue.prototype', router)

        // if (status === 400){
        throw data
        // } else {
        //     throw false;
        // }
        // Vue.prototype.$vs.notify({
        //     title:'Предупреждение!',
        //     text:'Заполните все поля',
        //     color:'danger'
        // });

        // console.log('1', data)


        // throw { message, response: { status, config: { url }, data } };
    })
export default instance;
