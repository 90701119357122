
export default [
    // {
    //     path: 'clients',
    //     name: 'clients',
    //     icon:'user',
    //     component: () => import('@/pages/Clients/Clients'),
    // },
    {
        path: 'main_page',
        name: 'main_page',
        icon:'home',
        component: () => import('@/pages/MainPage/MainPage'),
    },
    {
        path: 'add_client',
        name: 'add_client',
        icon:'user-add',
        component: () => import('@/pages/AddClient/AddClientComponents'),
    },
    {
        path: 'customer_request',
        name: 'customer_request',
        icon:'plus-square',
        component: () => import('@/pages/CustomerRequest/CustomerRequest'),
    },
    {
        path: 'kanban',
        name: 'kanban',
        icon:'appstore',
        component: () => import('@/pages/Kanban/Kanban'),
    },
    {
        path: 'reports',
        name: 'reports',
        icon:'file-text',
        component: () => import('@/pages/Reports/Reports'),
    },

    // {
    //     path: 'deals',
    //     name: 'deals',
    //     icon:'unordered-list',
    //     component: () => import('@/pages/Deals/Deals'),
    // },

];
