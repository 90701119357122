import axios from '@/configs/axios';

const state = () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
    profile: null,
    // block_final_grades: [],
});

const getters = {
    // GET_BLOCK_FINAL_GRADES: s => s.block_final_grades,
    isLoggedIn: state => !!state.user,
    accessToken: state => state.user?.access || '',
    GET_PROFILE_INFO: s => s.profile,
};

const mutations = {
    // SET_BLOCK_FINAL_GRADES(state, value){
    //     if(state.block_final_grades) {
    //         state.block_final_grades.push(...value.results)
    //     }else{
    //         state.block_final_grades = value.results
    //     }
    // },
    loginSuccess(state, user) {
        state.user = user;
    },
    loginFailure(state) {
        state.user = null;
    },
    logout(state) {
        state.user = null;
    },
    setRefreshToken(state, token) {
        state.user.access = token.access;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    SET_PROFILE_INFO(state, value){
        state.profile = value.user;
    }
};

const actions = {
    async BLOCK_FINAL_GRADES({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('block_final_grades/list/', {params:payload})
                .then(response => {
                    commit('SET_BLOCK_FINAL_GRADES', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    login({commit}, user) {
        return new Promise((resolve, reject) => {
            axios.post('users/api-token-auth/', user)
                .then(response => {
                    if (response.data.access) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                        commit('loginSuccess', response.data);
                    }
                    resolve(response.data);
                })
                .catch(error => {
                    localStorage.removeItem('user');
                    commit('loginFailure');
                    reject(error);
                });
        });
    },
    logout({commit}) {
        return new Promise((resolve) => {
            localStorage.removeItem('user');
            commit('logout');
            resolve();
        });
    },
    async PROFILE_INFO({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('users/profile_info/', {params:payload})
                .then(response => {
                    commit('SET_PROFILE_INFO', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    refreshToken({commit, state}) {
        return new Promise((resolve, reject) => {
            const refresh_token = state.user?.refresh;
            if (refresh_token) {
                try {
                    axios.post('users/api-refresh/', { refresh: refresh_token })
                        .then(response => {
                            console.log('response.data', response.data)
                            if (response.data.access) {
                                commit('setRefreshToken', response.data);
                                resolve(response.data);
                            } else {
                                commit('logout');
                                reject(new Error('Failed to refresh token'));
                            }
                        })
                        .catch(error => {
                            localStorage.removeItem('user');
                            console.log('test')
                            commit('logout');
                            reject(error);
                        });
                }catch (e) {
                    localStorage.removeItem('user');
                    console.log('test')
                    commit('logout');
                    reject(error);
                }

            } else {
                localStorage.removeItem('user');
                commit('logout');
                reject(new Error('No refresh token found'));
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
