import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import user from "@/store/modules/user";
import client from "@/store/modules/client";
import kanban from "@/store/modules/kanban";

export default new Vuex.Store({
    modules: {
        user,
        client,
        kanban,
    },
    strict: process.env.NODE_ENV !== "production",
});
